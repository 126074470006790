import React, { Component } from "react";

import logo from "../../images/logos/Rye_Labs_Logo_Black.svg";

import "./FooterContainer.scss";

class FooterContainer extends Component {
  render() {
    return (
      <footer>
        <div className="footer--content">
          <div className="footer--logo">
            <img alt="Rye Labs Logo" src={logo} />
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterContainer;
