import React, { Component } from "react";

import ContactForm from "../../Widgets/ContactForm/ContactForm";
import { ReactComponent as GrainShape } from "../../../images/svg/grain_shape.svg";
import { ReactComponent as RyeAccent } from "../../../images/svg/rye_logo_side.svg";

import johnPotter from "../../../images/team/john_potter.jpg";
import zvonimirBaljak from "../../../images/team/zvonimir_baljak.jpg";
import ximenaFuertes from "../../../images/team/ximena_fuertes.png";
import toniGrabic from "../../../images/team/toni_grabic.jpg";
import janna from "../../../images/team/janna.png";
import wellmmer from "../../../images/team/wellmmer.png";
import placeholder from "../../../images/team/placeholder.png";

import cialLogo from "../../../images/Cial_Logo.png";
import cbLogo from "../../../images/CB_Logo.png";
import researchIcon from "../../../images/icons/Research_Icon.png";
import strategyIcon from "../../../images/icons/Strategy_Icon.png";
import designIcon from "../../../images/icons/Design_Icon.png";
import developmentIcon from "../../../images/icons/Development_Icon.png";
import dunsguideMockup from "../../../images/Dunsguide_Desktop_Mockup.png";
import ryeFinanceMockups from "../../../images/Rye_Finance_Mockups.png";

import "./Home.scss";

class Home extends Component {
  render() {
    return (
      <div className="Home">
        <GrainShape className="grain-shape--1" />
        <div className="landing-banner">
          <h1>
            Distilling data driven insights
            <br /> into human driven products
          </h1>
        </div>
        <section id="about" className="landing-about">
          <RyeAccent className="rye-accent" />
          <p className="landing-about--description">
            We are an innovation lab focused on fintech and business data
            solutions. We believe that well designed products are as important
            as the technology that backs them.
          </p>
          <div className="landing-about--services">
            <div className="landing-about--service">
              <img
                className="landing-about--service-icon"
                alt="research icon"
                src={researchIcon}
              />
              <div className="landing-about--service-name">Research</div>
            </div>
            <div className="landing-about--service">
              <img
                className="landing-about--service-icon"
                alt="strategy icon"
                src={strategyIcon}
              />
              <div className="landing-about--service-name">Strategy</div>
            </div>
            <div className="landing-about--service">
              <img
                className="landing-about--service-icon"
                alt="design icon"
                src={designIcon}
              />
              <div className="landing-about--service-name">UX Design</div>
            </div>
            <div className="landing-about--service">
              <img
                className="landing-about--service-icon"
                alt="development icon"
                src={developmentIcon}
              />
              <div className="landing-about--service-name">Development</div>
            </div>
          </div>
        </section>
        <section id="work" className="landing-work">
          <h2 className="landing-work--heading">Our work</h2>
          <div className="landing-work--finance">
            <RyeAccent className="rye-accent" />
            <div className="landing-work--name-container">
              <h3 className="landing-work--name">Rye Finance</h3>
              <div className="landing-work--name-link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.finove.com.br"
                >
                  <i className="icon icon-link" />
                </a>
              </div>
            </div>
            <p className="landing-work--description">
              Rye Finance is a white label Buy Now, Pay Later finance platform
              for B2B transactions. We provide the tools and platform for
              financial institutions to offer a fully automated financing
              process with support for a range of credit products inlcuding
              purchase financing, net terms, free installments, and more. We
              power the Brazilian B2B BNPL startup,{" "}
              <a href="https://www.finove.com.br" target="_blank">
                Finove
              </a>
              .
            </p>
            <a href="https://www.finove.com.br" target="_blank">
              <img
                src={ryeFinanceMockups}
                alt="rye finance app"
                className="landing-work--rye-finance-img"
              />
            </a>
          </div>
          <div className="landing-work--novo">
            <RyeAccent className="rye-accent" />
            <div className="landing-work--name-container">
              <h3 className="landing-work--name">Dunsguide</h3>
              <div className="landing-work--name-link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.dunsguide.com"
                >
                  <i className="icon icon-link" />
                </a>
              </div>
            </div>
            <p className="landing-work--description">
              Dunsguide is a business network for discovering suppliers and
              partners across Latin America.
            </p>
            <a href="https://www.dunsguide.com" target="_blank">
              <img
                src={dunsguideMockup}
                alt="dunsguide desktop"
                className="landing-work--dunsguide-desktop-img"
              />
            </a>
          </div>
        </section>
        <section id="team" className="landing-team">
          <GrainShape className="grain-shape--2" />
          <h2 className="landing-team--heading">Meet the team</h2>
          <div className="team-content">
            <div className="team-member">
              <img
                className="team-member--image"
                alt="John Potter"
                src={johnPotter}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">John Potter</h3>
                <p className="team-member--title">Co-founder, CEO</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Los Angeles, CA</div>
                  <div className="team-member--social">
                    <a
                      href="https://twitter.com/johnmpotter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon icon-twitter" />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/johnpotter17/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon icon-linkedin-in" />
                    </a>
                    <a
                      href="https://github.com/johnmpotter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon icon-github" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Zvonimir Baljak"
                src={zvonimirBaljak}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Zvonimir Baljak</h3>
                <p className="team-member--title">Co-founder, Data & Biz Dev</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Zagreb, Croatia</div>
                  <div className="team-member--social">
                    <a
                      href="https://twitter.com/ZvoniBa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon icon-twitter" />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/zvonimir-baljak-85082a1a/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon icon-linkedin-in" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Toni Grabic"
                src={toniGrabic}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Toni Grabic</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Zagreb, Croatia</div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Ximena Fuertes"
                src={ximenaFuertes}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Ximena Fuertes</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">
                    Mar del Plata, Argentina
                  </div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Luka Kelava"
                src={placeholder}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Luka Kelava</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Zagreb, Croatia</div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Caio Neves"
                src={placeholder}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Caio Neves</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">
                    Petropolis, Brazil
                  </div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Jannayna de Amorim"
                src={janna}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Jannayna de Amorim</h3>
                <p className="team-member--title">Designer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Natal, Brazil</div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Denis Koronic"
                src={placeholder}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Denis Koronic</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Zagreb, Croatia</div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Maximiliano Carrizo"
                src={placeholder}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Maximiliano Carrizo</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">
                    Buenos Aires, Argentina
                  </div>
                </div>
              </div>
            </div>
            <div className="team-member">
              <img
                className="team-member--image"
                alt="Wellmmer Lucas"
                src={wellmmer}
              />
              <div className="team-member--info">
                <h3 className="team-member--name">Wellmmer Lucas</h3>
                <p className="team-member--title">Engineer</p>
                <div className="team-member--additional">
                  <div className="team-member--location">Sorocaba, Brazil</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="landing-partners">
          <h3 className="landing-partners--heading">Partners</h3>
          <a href="https://www.cialdnb.com/" target="_blank">
            <img
              src={cialLogo}
              alt="CIAL D&B logo"
              className="landing-partners--cial"
            />
          </a>
          <img
            src={cbLogo}
            alt="CB Alliance logo"
            className="landing-partners--cb"
          />
        </section>
        <section id="contact" className="landing-contact">
          <RyeAccent className="rye-accent" />
          <ContactForm />
        </section>
      </div>
    );
  }
}

export default Home;
