import React, { Component } from "react";
import classNames from "classnames";
import FloatingLabel, {
  floatingStyles,
  focusStyles,
  inputStyles,
  labelStyles,
  spanStyles,
} from "floating-label-react";

import "./ContactForm.scss";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class ContactForm extends Component {
  state = {
    formData: {
      "form-name": "contact",
      name: "",
      email: "",
      message: "",
    },
    isSubmitting: false,
    success: false,
    error: "",
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
      ...this.state.formData,
      [name]: value,
    };

    this.setState({
      formData: newFormData,
    });
  };

  canSubmit = () => {
    const {
      formData: { name, email, message },
    } = this.state;

    if (name.length && email.length && message.length) return true;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isSubmitting: true });

    // Form data stored with Netlify
    // https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/

    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode(this.state.formData),
    })
      .then((response) => this.setState({ isSubmitting: false, success: true }))
      .catch((error) => this.setState({ isSubmitting: false, error: error }));
  };

  buttonClassNames = () => {
    return classNames({
      "form-button": true,
      loading: this.state.isSubmitting,
    });
  };

  successMessage = () => {
    const {
      formData: { email },
    } = this.state;

    return (
      <div>
        <h3 className="landing-contact--heading">
          Awesome, thanks for reaching out!
        </h3>
        <p className="landing-contact--subheading">
          We'll get back to you before you know it at {email}
        </p>
      </div>
    );
  };

  render() {
    const { success } = this.state;

    if (success) return this.successMessage();

    return (
      <div className="ContactForm">
        <h3 className="landing-contact--heading">We'd love to chat</h3>
        <p className="landing-contact--subheading">
          Send us a message below or email us at hello@ryelabs.co
        </p>
        <form
          action="/contact"
          method="POST"
          onSubmit={this.handleSubmit}
          className="contact-form"
        >
          <input
            type="hidden"
            name="form-name"
            value={this.state.formData["form-name"]}
          />
          <FloatingLabel
            id="name"
            name="name"
            placeholder="Your name"
            styles={inputStyle}
            type="text"
            value={this.state.formData.name}
            onChange={this.handleChange}
          />
          <FloatingLabel
            id="email"
            name="email"
            placeholder="Your email"
            styles={inputStyle}
            type="email"
            value={this.state.formData.email}
            onChange={this.handleChange}
          />
          <FloatingLabel
            id="message"
            element="textarea"
            name="message"
            placeholder="Write words here..."
            styles={inputStyle}
            type="text"
            value={this.state.formData.message}
            onChange={this.handleChange}
          />
          <div className="ContactForm--button-container">
            <button
              disabled={!this.canSubmit()}
              className={this.buttonClassNames()}
            >
              Send
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const inputStyle = {
  input: {
    ...inputStyles,
    width: "100%",
    border: "1px solid #D0D3D3",
    borderColor: "#D0D3D3",
    borderRadius: "10px 20px",
    padding: "13px 15px 13px",
  },
  focus: {
    ...focusStyles,
    borderColor: "#A48A6F",
  },
  label: {
    ...labelStyles,
    display: "block",
    marginTop: "20px",
    width: "100%",
    color: "#8F9090",
  },
  span: {
    ...spanStyles,
    padding: "18px 4px 18px",
    margin: "0 11px",
  },
  floating: {
    ...floatingStyles,
    color: "#8F9090",
    padding: "0 4px",
    margin: "0 11px",
    background: "#ffffff",
    fontSize: ".9em",
  },
};

export default ContactForm;
