import React, { Component } from "react";
import { elastic as Menu } from "react-burger-menu";

import logo from "../../images/logos/Rye_Labs_Logo_Black.svg";

import "./HeaderContainer.scss";

class HeaderContainer extends Component {
  state = {
    menuOpen: false,
  };

  headerNav = () => {
    return (
      <div className="header-nav--container">
        <nav className="header--nav">
          <ul className="header--nav--list">
            <li className="header--nav--list-item">
              <a href="#work" onClick={this.closeMenu}>
                Our work
              </a>
            </li>
            <li className="header--nav--list-item">
              <a href="#team" onClick={this.closeMenu}>
                The team
              </a>
            </li>
            <li className="header--nav--list-item">
              <a href="#contact" onClick={this.closeMenu}>
                Contact us
              </a>
            </li>
            <li className="header--nav--list-item">
              <a
                href="https://angel.co/company/rye-labs/jobs"
                target="_blank"
                rel="noopener noreferrer"
              >
                We're hiring!
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    return (
      <header>
        <div className="header container">
          <div className="header--logo">
            <a href="/">
              <img alt="Rye Labs Logo" src={logo} />
            </a>
          </div>
          <div className="header-menu--container">
            <div className="header-menu--full">{this.headerNav()}</div>
            <div className="header-menu--slide">
              <Menu
                isOpen={this.state.menuOpen}
                menuOpen
                right
                className={"hamburger-menu"}
                bodyClassName={"overlay-open"}
                pageWrapId={"page-wrapper"}
                outerContainerId={"App"}
              >
                {this.headerNav()}
              </Menu>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderContainer;
