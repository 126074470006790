import React, { Component } from "react";
import { Route } from "react-router-dom";
import ReactGA from "react-ga";

import "./App.scss";
import HeaderContainer from "./HeaderContainer/HeaderContainer";
import Home from "./Page/Home/Home";
import FooterContainer from "./FooterContainer/FooterContainer";

export default class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_REAL_ENV === "production") {
      ReactGA.initialize("UA-115096758-1");
      /* Currently we only have 1 page, so only need to track on first load */
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <div className="App">
        <HeaderContainer />
        <main className="page-wrapper">
          <Route exact path="/" component={Home} />
        </main>
        <FooterContainer />
      </div>
    );
  }
}
